<template>
  <section id="Puente" class="container my-5" data-aos="fade-up">
    <div class="text-center mb-5">
      <h2 class="fw-bold">{{ data.title }}</h2>
      <p>{{ data.subtitle }}</p>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-12 col-md-4 mb-4 ">
        <div class="card shadow-sm puente-cards">
          <div class="regular-card">
            <div class="text-center">
              <img class="card-img img" :src="require(`../../assets/${data.cardOne.img}`)"
                   alt="Imagen apartado emprendimientos">
            </div>
            <div class="card-body">
              <h6 class="mb-3 card-title pb-2">{{ data.cardOne.title }}</h6>
              <div v-html="data.cardOne.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 green-card-container">
        <div class="card py-5 shadow-sm green-card text-center">
          <div>
            <div class=" mb-3">
              <img class="card-img img" :src="require(`../../assets/${data.cardTwo.logo}`)" alt="Logo de wetforest">
            </div>
            <div class="card-body">
              <div class="mb-5">
                <img style="width: 130px" :src="require(`../../assets/${data.cardTwo.img}`)"
                     alt="Icono de puente resaltando union entre emprendimientos e inversionistas">
              </div>
              <p class=" fw-bold">{{ data.cardTwo.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 regular-card">
        <div class="card shadow-sm puente-cards">
          <div class="regular-card">
            <div class="text-center">
              <img class="card-img img" :src="require(`../../assets/${data.cardThree.img}`)"
                   alt="Icono representando inversionistas">
            </div>
            <div class="card-body">
              <h6 class="mb-3 card-title pb-2">{{ data.cardThree.title }}</h6>
              <div v-html="data.cardThree.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from '../../store';

export default {
  name: "Puente",
  data() {
    return {
      data: {
        title: 'Somos un excelente puente',
        subtitle: 'entre emprendedores e inversionistas',
        cardOne: {
          img: 'img/beneficio-emprendimiento.webp',
          title: 'EMPRENDIMIENTOS',
          content: `
          <p>Talento y Experiencia de Empresarios y Ejecutivos de amplia trayectoria.
           <br><br>Apoyando al emprendedor y su equipo semana a semana.
            <br><br>Desarrollando Talento.
             <br><br>Hablando el lenguaje de los emprendedores.</p>
        `
        },
        cardTwo: {
          logo: 'logo/logo.webp',
          img: 'img/img-puente.svg',
          text: 'Venture Services'
        },
        cardThree: {
          img: 'img/beneficio-inversionista.webp',
          title: 'INVERSIONISTA',
          content: `
          <p>Apoyando para maximizar la oportunidad.
           <br><br>Reduciendo el riesgo de la inversión.
           <br><br>Preparando al Emprendimiento para nuevos levantamientos de inversión.</p>
        `
        }
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      if (store.state.sections.bridge) {
        this.data = store.state.sections.bridge.components;
      }
    }
  }
}
</script>

<style scoped>

.regular-card {
  height: 651px;
}

h6 {
  border-bottom: 1px solid #14993C;
  font-weight: bold;
}

.green-card-container {
  max-width: 290px;
}

.green-card {
  background-color: rgba(32, 178, 76, 0.3);
}


img {
  width: 200px;
}

@media (min-width: 762px) {
  .puente-cards {
    min-height: 550px;
  }
}

@media (max-width: 982px) {

  .regular-card {
    height: auto;
  }

  .green-card-container {
    max-width: none;
  }

}


</style>
